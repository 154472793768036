import React, { useState, useRef } from 'react';
import axios from 'axios';

const ChatWithGPT = () => {
  const [response, setResponse] = useState('');
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  const handleSendClick = async (input) => {
    try {
      const result = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: input }],
      }, {
        headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json',
        },
      });

      const gptResponse = result.data.choices[0].message.content;
      setResponse(gptResponse);
      speak(gptResponse);
    } catch (error) {
      console.error('Error fetching data from OpenAI:', error);
    }
  };

  const handleVoiceInput = () => {
    if (!recognitionRef.current) {
      recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognitionRef.current.lang = 'ko-KR';
      recognitionRef.current.interimResults = false;
      recognitionRef.current.maxAlternatives = 1;

      recognitionRef.current.onresult = (event) => {
        const voiceInput = event.results[0][0].transcript;
        handleSendClick(voiceInput);
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };
    }

    if (isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const speak = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'ko-KR'; // 한국어로 응답 출력
      utterance.volume = 1; // 음량 설정 (0.0 ~ 1.0)
      utterance.rate = 1;   // 말하기 속도 설정 (0.1 ~ 10)
      utterance.pitch = 1;  // 음 높이 설정 (0.0 ~ 2.0)

      window.speechSynthesis.speak(utterance);
    } else {
      alert('이 장치는 음성 합성을 지원하지 않습니다.');
    }
  };

  return (
    <div>
      <h1>Chat with GPT Speaking</h1>
      <button onClick={handleVoiceInput}>{isListening ? '🛑 Stop' : '🎤 Speak'}</button>
      <p>Response: {response}</p>
    </div>
  );
};

export default ChatWithGPT;
