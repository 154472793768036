import React, { useState } from 'react';
import axios from 'axios';

import { Form, Button, Alert, Container } from 'react-bootstrap';

function Login(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault(); // 폼 제출 시 페이지가 새로고침 되는 것을 방지
        setLoading(true); // 로딩 상태 시작
        setError(null);   // 에러 초기화

        try {
            const response = await axios.post(
                'http://localhost:8080/api/login', 
                { userId: email, password: password} ,
                { withCredentials: true }  // 쿠키와 인증 정보를 포함하도록 설정
            );

            console.log('로그인 성공:', response.data);
        // 여기서 로그인 성공 후 처리 (예: 토큰 저장, 리디렉션 등)
        } catch (error) {
            setError(error.response ? error.response.data.message : '로그인 실패');
            console.error('로그인 에러:', error);
        } finally {
            setLoading(false); // 로딩 상태 종료
        }
    };

    return (
        <Container className="mt-5">
        <h2>로그인</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleLogin}>
            <Form.Group controlId="formEmail">
            <Form.Label>이메일</Form.Label>
            <Form.Control
                type="email"
                placeholder="이메일을 입력하세요"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mt-3">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
                type="password"
                placeholder="비밀번호를 입력하세요"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
            {loading ? '로그인 중...' : '로그인'}
            </Button>
        </Form>
        </Container>
  );
}

export default Login