import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');

  // 서버로 요청을 보내는 함수
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // 주어진 URL로 POST 요청
      const result = await axios.post('https://chatgpt.com/g/g-67306b88cac08190a7d0e0c05ba5df00-remini-homteu-c', {
        prompt: prompt,
      });
      // 요청에 대한 응답 저장
      setResponse(result.data.response);
    } catch (error) {
      console.error('Error fetching response:', error);
      setResponse('Something went wrong, please try again.');
    }
  };

  return (
    <div className="App">
      <h1>Chat with Custom GPT API</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Ask me anything..."
        />
        <button type="submit">Send</button>
      </form>
      {response && (
        <div className="response">
          <h2>Response:</h2>
          <p>{response}</p>
        </div>
      )}
    </div>
  );
}

export default App;
