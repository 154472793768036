import React, { useState } from 'react';
import axios from 'axios';

const ChatWithGPT = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendClick = async () => {
    try {
      const result = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: input }],
      }, {
        headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json',
        },
      });

      const gptResponse = result.data.choices[0].message.content;
      setResponse(gptResponse);
      speak(gptResponse);
    } catch (error) {
      console.error('Error fetching data from OpenAI:', error);
    }
  };

  const speak = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);

      // 모바일 장치에서 좀 더 나은 음성 출력을 위해 음성 설정 최적화
      utterance.volume = 1; // 음량 (0.0 - 1.0)
      utterance.rate = 1;   // 말하는 속도 (0.1 - 10, 기본값 1)
      utterance.pitch = 1;  // 음 높이 (0.0 - 2.0, 기본값 1)

      // 일부 모바일 장치에서는 오디오 출력을 위해 반드시 사용자의 제스처가 필요합니다.
      utterance.onstart = () => {
        console.log('음성 재생 시작');
      };

      utterance.onend = () => {
        console.log('음성 재생 완료');
      };

      utterance.onerror = (event) => {
        console.error('음성 재생 중 오류 발생:', event.error);
      };

      // 실제로 음성 합성 재생
      window.speechSynthesis.speak(utterance);
    } else {
      alert('이 장치는 음성 합성을 지원하지 않습니다.');
    }
  };

  return (
    <div>
      <h1>Chat with GPT Speeking</h1>
      <textarea value={input} onChange={handleInputChange} placeholder="Ask something..." />
      <button onClick={handleSendClick}>Send</button>
      <p>Response: {response}</p>
    </div>
  );
};

export default ChatWithGPT;
